import React, { useEffect } from 'react';
import Navbar from './v2/Navbar';
import Banner from './v2/Banner';
import { isMobile } from '~src/utils/device';
import { FliqIntro } from './FliqIntro/index';
import Dex from './v3/Dex';
import SBP from './v3/SBP';
import Footer from './v3/Footer';
import PreFooter from './v3/PreFooter';

const HomePage: React.FC = () => {
  const mobile = isMobile();

  // min-h-screen overflow-hidden
  return (
    <div className="bg-black min-h-screen overflow-hidden">
      <header>
        <Navbar />
      </header>
      <div className="mx-auto">
        <section className="mx-auto">
          <Banner />
          <Dex />
          <SBP />
{/*           <FliqIntro /> */}
          <PreFooter />
        </section>
      </div>
      <footer className="Home_footer flex justify-center">
        <Footer />
      </footer>
    </div>
  );
};

export default HomePage;