import React, { useEffect, useState } from 'react';
import Card from '~src/components/Card';

const SBP: React.FC = () => {
  const goToSBP = () => {
    window.open("https://app.flashliquidity.finance/#/selfbalancing", "_blank");
  };

  return (
    <div>
      <div className='lg:mr-64 mt-24'>
        <Card
          title="For traders"
          description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
          buttonText="Start trading"
          buttonAction={goToSBP}
          className='shadow-glow'
        />
      </div>
      <div className='lg:ml-64 mt-24'>
        <Card
          title="For builders"
          description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
          buttonText="View docs"
          buttonAction={goToSBP}
          className='shadow-glowx'
        />
      </div>
      <div className='lg:mr-64 mt-24'>
        <Card
          title="For fun"
          description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
          buttonText="View docs"
          buttonAction={goToSBP}
          className='shadow-glowy'
        />
      </div>
    </div>
  );
};

export default SBP;
