import React, { useEffect, useRef, useState } from 'react';
import { ArbitrumIcon, AvalancheIcon, BannerArrowIcon, BaseIcon, DclIcon1, DclIcon19, DclIcon2, DclIcon3, DclIcon4, DclIcon5, PolygonIcon, ZkevmIcon } from '~src/components/layoutIcon/Icon';
import "./index.scss";
import "./bg.scss";
import PCBAnimation from '../../v2/PcbAnimation';

type FeatureCardProps = {
  Icon: React.ElementType;
  title: string;
  description: string;
  animationDelay?: string; // Add this line
};

const FeatureCard: React.FC<FeatureCardProps> = ({ Icon, title, description, animationDelay }) => {
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  const checkVisibility = () => {
    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      const threshold = 0.7; // Start animation when 25% of the element is visible

      const isElementVisible =
        rect.top + rect.height * threshold <= window.innerHeight && rect.bottom - rect.height * threshold >= 0;

      if (isElementVisible != isVisible) setIsVisible(isElementVisible);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkVisibility);
    checkVisibility(); // Initial check
    return () => {
      window.removeEventListener('scroll', checkVisibility);
    };
  }, [isVisible]);

  const cardStyle: React.CSSProperties = {
    opacity: 0, // Initial opacity set to 0
    animation: isVisible
      ? `slideInFromRight 2s cubic-bezier(0.68, -0.55, 0.27, 1.55)  ${animationDelay} forwards`
      : `slideOutToRight 2s cubic-bezier(0.68, -0.55, 0.27, 1.55)  forwards`,
    transition: !isVisible
      ? 'none'
      : 'opacity 0.5s ease-out, transform 0.5s ease-out', // Include transform in transitions
  };

  return (
    <div
      ref={cardRef}
      className="
        flex-1 
        sm:w-full 
        sm:mr-0 
        sm:mb-14 
        md:w-full 
        lg:mb-6 
        bg-secondary-gradient 
        rounded-xl 
        p-6
        transition-shadow 
        duration-300 
        hover:shadow-glowy
      "
      style={cardStyle}
    >
      <div className="flex justify-center items-center sm:overflow-hidden md:scale-110 md:transform ">
        <Icon/>
      </div>
      <h1
        className="mt-16 mb-2.5 text-32 gotham_font_bold sm:mt-10 sm:text-center md:mt-10 text-center md:text-26"
        style={{
          background: 'linear-gradient(to right, #667eea, #764ba2)',
          WebkitBackgroundClip: 'text',
          backgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        {title}
      </h1>
      <p className="text-lg gotham_font_light text-center md:text-base sm:text-center">
        {description}
      </p>
    </div>
  );
};

type NetworkIconProps = {
  Icon: React.ElementType; // This allows any React component
  animationDelay: string;
};

const NetworkIcon: React.FC<NetworkIconProps> = ({ Icon, animationDelay }) => {
  const [isVisible, setIsVisible] = useState(false);
  // Specify the type of element the ref will be attached to
  const [hasEnteredView, setHasEnteredView] = useState(false); // Tracks if it has been visible before
  const iconRef = useRef<HTMLDivElement>(null);

  const checkVisibility = () => {
    if (iconRef.current) {
      const rect = iconRef.current.getBoundingClientRect();
      const threshold = 0.2; // Start animation when 25% of the element is visible

      const isElementVisible =
        rect.top + rect.height * threshold <= window.innerHeight &&
        rect.bottom - rect.height * threshold >= 0;

      // Trigger visibility updates only when necessary
      if (isElementVisible !== isVisible) {
        setIsVisible(isElementVisible);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkVisibility);
    checkVisibility();
    return () => {
      window.removeEventListener('scroll', checkVisibility);
    };
  }, []);

  const iconStyle: React.CSSProperties = {
    opacity: 0,
    animation: isVisible
      ? `slideUpFromBottom 1s ease-out ${animationDelay} forwards`
      : `slideDownFromTop 1s ease-out 0s forwards`,
  };


  return (
    <div
      ref={iconRef}
      style={iconStyle}
    >
      <Icon className="hover:scale-150 transform transition-transform duration-300 ease-in-out" />
    </div>
  );
};

const Dex = () => {
  function learnMore() {
    window.open('https://app.flashliquidity.finance/#/swap');
  }
  function addLiquidity() {
    window.open('https://app.flashliquidity.finance/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174');
  }
  return (
    <div className="relative mt-24">
      <div className="relative z-10 mx-auto mt-64 sm:mt-24 md:mt-52 lg:w-4/5 sm:w-full md:w-full">
        <div className="relative z-10 mb-16 sm:justify-center md:justify-center sm:w-full md:w-full sm:text-center sm:mb-11 md:text-center md:mb-11">
          <div className="relative mb-4 sm:w-full md:w-full">
            <p style={{
              background: 'linear-gradient(to right, #ce1fde, #9231FD, #667eea)', // Adjust the gradient colors as needed
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              display: 'inline' // Ensures the gradient applies to the text only, not the entire line
            }} className="absolute bottom-2 left-0 mb-4 text-white gotham_font_bold  text-42 sm:text-4xl  md:text-4xl md:relative sm:relative">
              Onchain Aggregator
            </p>
          </div>
          <p className="text-white gotham_font_bold text-26 sm:text-xl md:text-xl sm:px-4">

          </p>
        </div>
        <div className="relative z-10 flex flex-wrap gap-14 justify-between items-stretch text-white mb-8 grid grid-cols-3 sm:grid-cols-1 sm:pl-4 sm:pr-4 sm:mb-0 md:px-6 md:mb-24 md:gap-6">
          <FeatureCard
            animationDelay="0.2s" // First card
            Icon={DclIcon3}
            title="Swap tokens"
            description="Trade instantly from your wallet, no deposit needed."
          />
          <FeatureCard
            animationDelay="0.4s" // Second card, delayed by 0.2s
            Icon={DclIcon4}
            title="Self custodial"
            description="Always retain control over your assets while trading."
          />
          <FeatureCard
            animationDelay="0.6s" // Third card, delayed by 0.4s
            Icon={DclIcon5}
            title="Fast on-board"
            description="No registration required, start trading immediately."
          />
        </div>
        <div className="relative bg-secondary-gradient pb-12 rounded-xl mt-4 mb-20 sm:-mt-1 mx-auto lg:w-4/5 sm:w-full sm:px-5 shadow-glowx animate-pulse-shadow">
          <div className="text-center pt-6 mb-10">
            <span style={{
              background: 'linear-gradient(to right, #ce1fde, #9231FD, #667eea)', // Adjust the gradient colors as needed
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              display: 'inline' // Ensures the gradient applies to the text only, not the entire line
            }} className="text-white text-42 sm:text-2xl md:text-xl gotham_font_bold ">Supported networks</span>
          </div>
          <div className="flex justify-around sm:mt-2">
            <NetworkIcon Icon={ArbitrumIcon} animationDelay="0s" />
            <NetworkIcon Icon={AvalancheIcon} animationDelay="0.2s" />
            <NetworkIcon Icon={BaseIcon} animationDelay="0.4s" />
            <NetworkIcon Icon={PolygonIcon} animationDelay="0.6s" />
            <NetworkIcon Icon={ZkevmIcon} animationDelay="0.8s" />
          </div>
        </div>
        <div className="z-20 relative flex justify-center mb-40 sm:mb-44 md:mb-44">
          <div className="absolute flex sm:block sm:w-full sm:pl-4 sm:pr-4 mb:block mb:w-full mb:pl-4 mb:pr-4">
            <div
              onClick={learnMore}
              className="flex items-center justify-center gotham_font_bold text-lg  mr-5 border border-primary hover:border-hightGreenColor px-10 rounded-lg cursor-pointer text-white h-14 font-extrabold w-max  sm:w-full md:w-fit sm:mb-6 md:mb-6 md:mt-6"
            >
              Trade
            </div>
            <div
              onClick={addLiquidity}
              className="sm:hidden flex items-center justify-center gotham_font_bold bg-primary rounded-lg  cursor-pointer text-lg text-white h-14 px-10 font-extrabold w-max hover:bg-hightGreenColor sm:w-full md:w-fit md:mt-6 sm:px-2"
            >
              Add Liquidity{' '}
              <BannerArrowIcon className="ml-2 sm:transform sm:scale-75 sm:origin-left md:transform md:scale-75 md:origin-left sm:ml-1 md:ml-1" />
            </div>
            <div
              onClick={addLiquidity}
              className="lg:hidden md:hidden flex items-center justify-center gotham_font_bold bg-primary rounded-lg cursor-pointer text-lg text-white h-14 px-10 font-extrabold w-max hover:bg-hightGreenColor sm:w-full md:w-fit md:mt-6 sm:px-2"
            >
              Add Liquidity{' '}
              <BannerArrowIcon className="ml-2 sm:transform sm:scale-75 sm:origin-left md:transform md:scale-75 md:origin-left sm:ml-1 md:ml-1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dex;
