import React, { useEffect, useRef, useState } from "react";
import { BannerArrowIcon, SelfBalancingIcon } from '~src/components/layoutIcon/Icon';
import "./index.scss";
import PCBAnimation from "~src/pages/home/v2/PcbAnimation";

interface CardProps {
  title: string;
  description: string;
  buttonText: string;
  buttonAction: () => void;
  className: string | null;
}

const GradientText: React.FC<{ text: string; className?: string }> = ({ text, className }) => (
  <h1
    style={{
      background: "linear-gradient(to right, #ce1fde, #9231FD, #667eea)",
      WebkitBackgroundClip: "text",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      display: "inline",
    }}
    className={`text-42 gotham_font_bold sm:text-4xl md:text-4xl xs:text-2xl ${className}`}
  >
    {text}
  </h1>
);

const CardButton: React.FC<{ text: string; onClick: () => void; className?: string }> = ({ text, onClick, className }) => (
  <div
    onClick={onClick}
    className={`flex items-center justify-center bg-primary gotham_font_bold rounded-lg px-8 cursor-pointer text-lg text-white h-14 font-extrabold w-max hover:opacity-80 transition-opacity duration-500 transition-shadow duration-300 hover:shadow-glow ${className}`}
  >
    {text}
    <BannerArrowIcon className="ml-2 transform scale-75 origin-left" />
  </div>
);

const Card: React.FC<CardProps> = ({ title, description, buttonText, buttonAction, className }) => {
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  const checkVisibility = () => {
    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      const threshold = 0.8; // Adjust visibility threshold
      const isElementVisible =
        rect.top + rect.height * threshold <= window.innerHeight && rect.bottom - rect.height * threshold >= 0;


      if(isVisible != isElementVisible) setIsVisible(isElementVisible);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkVisibility);
    checkVisibility(); // Initial check
    return () => {
      window.removeEventListener('scroll', checkVisibility);
    };
  }, [isVisible]);

  return (
    <div ref={cardRef} className={`relative bg-secondary-gradient pb-8 top-2 transition-shadow duration-500 ease-in-out ${isVisible && className ? className : ""}`} style={{ borderRadius: "1.5rem" }}>
      <div className="relative z-10 mt-12 flex items-center mx-auto sm:mt-12 sm:flex-col-reverse md:flex-col-reverse md:mt-12 sm:px-9 md:px-9 sm:w-full md:w-full">
        {/* Description Section */}
        <div className="relative w-fit sm:justify-center sm:text-center sm:mb-6">
          <div className="flex justify-center items-center text-white mt-8 mb-12 lg:hidden">
            <p className="text-left text-lg md:text-26">{description}</p>
          </div>
          <div className="lg:hidden flex sm:flex-col justify-center items-center relative mt-12">
            <CardButton text={buttonText} onClick={buttonAction} />
          </div>
        </div>

        {/* Title and Button Section */}
        <div className="text-white z-10 ml-7 pt-8 sm:ml-0 sm:text-center">
          <GradientText text={title} className="mt-12 lg:ml-12 mb-15.5" />
          <p className="mt-8 mb-16 ml-12 mr-12 flex items-center text-26 sm:justify-start sm:text-lg sm:hidden md:hidden">
            {description}
          </p>
          <CardButton
            text={buttonText}
            onClick={buttonAction}
            className="z-20 sm:hidden md:hidden sm:w-full md:w-full ml-12 mb-8"
          />
        </div>
      </div>
    </div>
  );
};

export default Card;